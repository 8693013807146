/*PRELOADING------------ */
#overlayer {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99998;
    background: white;
    top: 0;
    left: 0;
    max-height: 100vh;
}
.loader-overlay {
    display: inline-block;
    position: absolute;
    z-index: 99999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.overlayScroll {
    overflow: hidden;
}
