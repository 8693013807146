.signUP-admin-left{
    background-color: #F4F5F4;

    .signupTop{
        max-width: 300px;
    }

    .logo{
        max-width: 300px;
    }
}

.nav-notification .nav-item-toggle::before{
    background-color: transparent !important;
    margin-left: -3px;
}

.nav-notification .nav-item-toggle.unread::before{
    background-color: #ff4d4f !important;
}

.btn-primary{
    background-color: #31a7a9;
    border-color: #31a7a9;
}

.signUp-admin-right .card .card-header h6 span{
    color: #31a7a9;
}

.justify-content-between {
    justify-content: space-between;
  }

.ant-timeline {
    box-sizing: border-box;
    color: #272b41;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    font-feature-settings: "tnum","tnum";
    margin: 0;
    padding: 0;
    list-style: none;
}

.ant-timeline-item {
    position: relative;
    margin: 0;
    padding-bottom: 20px;
    font-size: 14px;
    list-style: none;
}

.ant-timeline-item-tail {
    position: absolute;
    top: 10px;
    left: 4px;
    height: calc(100% - 10px);
    border-left: 2px solid #f0f0f0;
}

.ant-timeline-item-head-custom {
    position: absolute;
    top: 5.5px;
    left: 5px;
    width: auto;
    height: auto;
    margin-top: 0;
    padding: 3px 1px;
    line-height: 1;
    text-align: center;
    border: 0;
    border-radius: 0;
    transform: translate(-50%,-50%);
}

.ant-timeline-item-content {
    position: relative;
    top: -5px;
    margin: 0 0 0 26px;
    word-break: break-word;
    display: inline-block;
}

.ant-timeline-item .ant-timeline-item-content {
    margin: 0px 0px 0px 32px;
    font-size: 14px !important;
}

.ant-timeline-item .ant-timeline-item-content .timeline-content-inner .timeline-content-time {
    min-width: 65px;
    font-weight: 600;
    color: rgb(134, 142, 174);
}

.ck-editor__editable {
    min-height: 200px;
}

.dropdown-results{
    max-height: 300px;
    overflow-y: auto;
    right: auto !important;
    top: 72px !important;
    border-radius: 0px 0px 10px 10px;
    max-width: 300px;
    width: 100%;
}

.ellipsis {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.signUP-admin-left__content{
    img{
        width: 100%;
        max-width: 200px;
        margin: 0 auto;
        margin-top: 50px;
    }
}

.signUP-admin-left__img {
    margin: 0 auto;
    top: auto !important;
    left: auto !important;
    transform: none;
    position: inherit !important;
}

small svg{
    height: 16px;
    width: 16px;
}